:root {
  --body-background: #f7f7fa;
  --medium-pink: #ec4e8c;
  --stormy-blue: #547aa6;
  --lightblue: #72adf1;
  --lighterblue: #c6def9;
  --stormy-purple: #6E4FB3;
  --lightpurple: #865FD9;
  --white: #ffffff;
  --kiwi-green: #9adb54;
  --background: #f6f6f6;
  --orange: #f5a623;
  --black: #181818;
  --light-grey: #f1f1f1;
  --warm-grey: #6f6f6f;
  --warm-grey-two: #838383;
  --warm-red: #ff0050;
  --light-red: #ff9595;
  --colorful-1: #A557F2;
  --colorful-2: #9177F2;
  --colorful-3: #888DF2;
  --colorful-4: #85A0F2;
  --colorful-5: #77B9F2;
  --filter-background: #f1f1f1;
  --chart-background: #ffffff;
  --box-shadow-0: rgba(0, 0, 0, 0.2);
  --box-shadow-1: rgba(0, 0, 0, 0.14);
  --box-shadow-2: rgba(0, 0, 0, 0.12);
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 8px;
  background-color: #f6f7ff;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: linear-gradient(to right, #fff, #e4e4e4);
  border: 1px solid #aaa;
}

::-webkit-scrollbar-thumb:hover {
  background: #f6f7ff;
}

::-webkit-scrollbar-thumb:active {
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-size: 14px;
  margin: 0;
  background-color: var(--body-background);
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", Arial, sans-serif, "Apple Color Emoji", Droid Sans, Helvetica Neue, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
}

p {
  overflow-wrap: break-word;
  margin: 0;
}

// TODO review from here
button:focus {
  outline: transparent;
}

label {
  margin: 0; // overrides Bootstrap
}

.modal {
  top: 60px;
  margin: 0 auto;
  left: calc(0% + 250px);
}

.slick-slider {
  .slick-arrow {
    width: 50px;
    height: 50px;
    z-index: 1001;

    &.slick-prev {
      left: 0;
    }

    &.slick-next {
      right: 0;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    color: black;
    font-size: 50px;
  }

  .slick-dots {
    bottom: -15px;

    li button:before {
      font-size: 30px;
    }
  }
}

.svg-menu {
  margin-top: 8px;
  cursor: pointer;
  width: 200%;
  height: 200%;
}

.sc-box {
  background-color: #fff;
  border-radius: 0 0 15px 15px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
    0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 20px;
}

.email-editor-wrapper-class {
  min-height: 300px;
  border: 1px solid rgba(0, 0, 0, 0.23);
}

.email-editor-editor-class {
  padding: 0 15px;
  height: 100%;
}

.public-DraftStyleDefault-block {
  margin: 0;
}

@keyframes play {
  0% {
    transform: scale(1);
  }
  15% {
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.4);
  }
  25% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.4), 0 0 0 20px rgba(255, 255, 255, 0.2);
  }
  25% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.4), 0 0 0 30px rgba(255, 255, 255, 0.2);
  }
}
