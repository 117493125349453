.markdown {
  font-family:  -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  padding: 15px;
  h1, h2, h3, h4 {
    margin: 15px 0 15px 0;
    color: var(--stormy-blue)
  }
  table {
    td, th {
      border: 1px solid #000;
      padding: 5px;
    }
  }
}